'use strict';
import React, { useState, useEffect, memo } from 'react';

import { Button, Divider } from '@alifd/next';

import './App.css';

export default memo((props) => {
  return (
    <div className="flex-col mod">
      <div className="flex-row header">
        <div className="flex-row block">
          <div className="logo" />
          <div className="flex-row container">
            <Button
              className="our-passion"
              onClick={() => {
                window.location.hash = '#our-passion-tag';
              }}
              ghost={'true'}
              component={'a'}
              type={'normal'}
              text={false}
              onclick={'window.location.hash = "#our-passion-tag"'}
              herf={'#our-passion-tag'}
            >
              OUR PASSION
            </Button>
            <Button
              className="avatarapp"
              onClick={(e) => {
                window.location.hash = '#app-tag';
              }}
              ghost={'true'}
              component={'a'}
              onclick={'window.location.hash = "#app-tag"'}
              herf={'#app-tag'}
            >
              AVATARAPP
            </Button>
            <Button
              className="about"
              ghost={'true'}
              onClick={(e) => {
                window.location.hash = '#about-tag';
              }}
              component={'a'}
              onclick={'window.location.hash = "#about-tag"'}
              herf={'#about-tag'}
            >
              ABOUT US
            </Button>
            <Button
              className="contact"
              ghost={'true'}
              component={'a'}
              onclick={'window.location.hash = "#about-tag"'}
              onClick={() => {
                window.location.hash = '#about-tag';
              }}
              herf={'#about-tag'}
            >
              CONTACT US
            </Button>
          </div>
        </div>
      </div>
      <div className="flex-col headmain">
        <span className="info">Create interesting and unique AI APPs</span>
        <span className="summary">
          Using AI to bring users different joys and experiences, experiencing oneself in different worlds
        </span>
        <div className="flex-row body">
          <span className="scroll-down">SCROLL DOWN</span>
        </div>
      </div>
      <div className="flex-col our-passion-tag">
        <Button
          className="class-6"
          iconSize={'0'}
          size={'small'}
          component={'div'}
          ghost={'true'}
          text={true}
          disabled={true}
        >
          <a name="our-passion-tag"></a>
        </Button>
        <div className="flex-row block-1">
          <span className="our-passion-1">OUR PASSION</span>
          <div className="flex-col container-1">
            <div className="flex-col wrapper">
              <span className="more-fun">+More fun</span>
              <span className="title">LET USERS EXPERIENCE MORE NOVEL AI APPLICATIONS</span>
            </div>
            <div className="flex-col wrapper-1">
              <span className="better-use">+Better to use</span>
              <span className="summary-1">CREATE BETTER-USE PRODUCTS AND MAKE USERS’ EXPERIENCE MORE COMFORTABLE</span>
            </div>
          </div>
        </div>
        <div className="flex-col block-2">
          <span className="flex-col more">+More AI</span>
          <span className="summary-2">
            WE'RE CONSTANTLY PUSHING THE BOUNDARIES OF AI DEVELOPMENT AND APPS PUBLISHING.
          </span>
        </div>
      </div>
      <div className="main">
        <div className="app-tag">
          <Button
            className="class-5"
            size={'small'}
            component={'div'}
            iconSize={'0'}
            ghost={'true'}
            text={true}
            disabled={true}
          >
            <a name="app-tag"></a>
          </Button>
          <div className="flex-col container-2">
            <div className="flex-row wrapper-2">
              <div className="flex-col group">
                <div className="action-bg" />
                <span className="photo-production">AI photo production</span>
              </div>
              <div className="flex-row item-long-wrapper">
                <span className="article">
                  Just upload a personal portrait photo, and AI will generate various photos for you, including
                  professional photos, wedding photos, Meigao photos, and more.{' '}
                </span>
                <div className="item-long" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-col class-2">
          <span className="user-talks">USER TALKS</span>
        </div>
        <div className="flex-col wrapper-3">
          <div className="flex-row jonathan-shelton-wrapper">
            <span className="jonathan-shelton">Jonathan Shelton</span>
          </div>
          <span className="article-1">
            I love how this app allows you some free features. And there are so many different filters! I would
            definitely recommend this app.
          </span>
        </div>
        <div className="flex-col container-3">
          <div className="vertical-line">
            <span className="callie-garcia">Callie Garcia</span>
          </div>
          <span className="summary-3">
            It even allows you to download the photo really easily. Yay my picture never looked better! Would recommend
            everyone to download this!
          </span>
        </div>
        {true && (
          <div className="flex-col container-4">
            <div className="flex-row cynthia-norton-wrapper">
              <span className="cynthia-norton">Cynthia Norton</span>
            </div>
            <span className="article-2">
              Everyone should check this one out! I'm having so much fun with this app This app does an amazing job
              using AI to change even your dullest photos into beautiful works of varying pop art.
            </span>
          </div>
        )}
        <div className="flex-col block-4">
          <div className="flex-row sunny-wrapper">
            <span className="sunny">Sunny</span>
          </div>
          <span className="article-3">
            I really think this app is fun, I get to see what things would look like how I would look like as a maid or
            in anime! It is so much fun and amazing!
          </span>
        </div>
        <div className="flex-col block-5">
          <div className="flex-row container-5">
            <div className="vertical-line-1" />
            <span className="sylver">Sylver</span>
          </div>
          <span className="article-4">
            The app works perfectly, and the ad ratio is definitely reasonable. I also love how you can regulate how
            fancy the image will look. The pictures look very pretty, and I highly recommend trying it out!
          </span>
          <div className="empty" />
        </div>
        <div className="flex-col block-6">
          <div className="flex-row george-wrapper">
            <span className="george">George</span>
          </div>
          <span className="article-5">
            Absolutely amazing!!! Great app for AI portraits, there are a lot of free options. All of them works well
            and makes great portraits! Having fun with seeing what me and my friends look like with the anime drawings!
          </span>
        </div>
      </div>
      <div className="flex-col about-tag">
        <Button
          className="class-4"
          size={'small'}
          iconSize={'0'}
          ghost={'true'}
          text={true}
          disabled={true}
          component={'div'}
        >
          <a name="about-tag"></a>
        </Button>
        <span className="about-1">ABOUT US</span>
        <span className="summary-4">
          We are a cutting-edge mobile applications company based in Hong Kong, specializing in AI-powered app and game
          development.
        </span>
        <div className="flex-row block-7">
          <Button
            className="class-3"
            size={'small'}
            iconSize={'0'}
            text={true}
            disabled={true}
            component={'div'}
            ghost={'true'}
          >
            <a name="contact-tag"></a>
          </Button>
          <div className="flex-col container-6">
            <span className="contact-1">CONTACT US</span>
            <span className="summary-5">
              If you have any questions, you can let us know and we will follow up and handle them later
            </span>
            <span className="summary-6">
              Email: fanjinyuan@joytutech.com Address: RM 511, 5/F, MING SANG INDL BLDG, 19-21 HING YIP ST, KWUN TONG,
              Hong Kong
            </span>
          </div>
          <div className="item" />
        </div>
      </div>
      <div className="footer">
        <Divider className="class-divider" />
        <div className="justify-center class-footer-content">
          <span className="class-7">| </span>
          <Button
            className="class-9"
            size={'small'}
            type={'normal'}
            component={'a'}
            ghost={'true'}
            text={true}
            disabled={false}
          >
            <a href="https://beian.miit.gov.cn">粤ICP备2023009542号-1</a>
          </Button>
          <span className="class-8"> |</span>
        </div>
      </div>
    </div>
  );
});
